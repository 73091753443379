import React, {createContext, useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {createFormData, utf8ToB64} from "../../helpers/helpers";
import {
    authGetGroupFirmsThunk,
    authThunkLoginAndRegisterUserFirm,
    authThunkRegisterCheckUser
} from "../../store/reducers/AuthReducer";
import {onSetFirmDT} from "../../store/reducers/RegisterReducer";
import Header from "./component/Header";
import {getRuleSettingsProject} from "../../selectors/common-selector";
import {useLang} from "../../context/langProvider";
import useAuthLookup from "../../HOOK/useAuthLookup";
import {useFormik} from "formik";
import {parsePhoneNumber, validateEmail, validatePhoneNumber} from "../../helpers/helpersListCountryCode";
import useSeconds from "../../HOOK/useSeconds";
import LoginRegistration from "./component/form/sign_up/login/LoginRegistration";
import {isEmail} from "../../helpers/utils";
import RegistrationUser from "./component/form/sign_up/register/RegistrationUser";
import {getRegisterSuccess} from "../../selectors/auth-selector";
import {IFURN_PRO_URL, LS_FIRM} from "../../constants";
import {useHistory} from "react-router-dom";
import {authLookupAC} from "../../store/actions/auth-actions";
// Create a context for Formik
const FormikContext = createContext();

// Custom hook to use Formik context
export const useFormikContextProviderRegister = () => {
    return useContext(FormikContext);
};
const INITIAL_FORMS_VALUES = {
    isRadioLoginEmail: true,
    isRadioShortPassword: true,
    isEditEmailOrPhone: true,
    name: '',
    password: '',
    email: '',
    phone: '',
    message_channel: '',
    master_password: '',
}

const SignUp = ({firm_code}) => {
    const {getT} = useLang();
    const dispatch = useDispatch();
    const project_rules = useSelector(getRuleSettingsProject);
    const registration_state = useSelector(getRegisterSuccess);
    const history = useHistory();

    const {
        auth_lookup,
        isAuthFind,
        isSendShortPassword,
    } = useAuthLookup();
    const [isFindUserForm, setIsFindUserForm] = useState(true)
    const [seconds, isButtonDisabled, handlerStartSeconds] = useSeconds()
    const formik = useFormik({
        initialValues: {
            ...INITIAL_FORMS_VALUES,
            email: '',
            phone: '',
        }, validate: (values) => {
            let errors = {};
            if (values.isRadioLoginEmail  && validateEmail(values.email)) {
                errors['email'] = getT("Email-адрес некорректен.");
            }
            const {countryCode, phoneNumberWithoutCode, mask} = parsePhoneNumber(values['phone']);
            const isValidPhone = validatePhoneNumber(countryCode, phoneNumberWithoutCode);
            if (!values.isRadioLoginEmail && !isValidPhone) {
                errors['phone'] = getT("Некоректний номер телефону")

            }
            console.log(errors, 'sign up error')
            return errors;
        },
        onSubmit: (values) => {
            let createData = {
                ['firms[]']: project_rules?.firm,
                name: values.name,
                phone: values.phone,
                email: values.email,
                password: values.password,
                message_channel: values.message_channel
            }
            if (isFindUserForm) {
                dispatch(authThunkLoginAndRegisterUserFirm({
                    firm: project_rules?.firm,
                    email: values.email || values.phone,
                    password: isAuthFind && !isSendShortPassword ? values.master_password : values.isRadioShortPassword ? values.password : values.master_password
                }))

                return;
            }
            let api_data = createFormData(createData);
            dispatch(authThunkRegisterCheckUser(api_data))

        }

    });
    useEffect(() => {
        let _uri = process.env.NODE_ENV === "development" ? 'https://newgo2.ifurn.pro' + window.location.pathname : window.location.href;

        let from_static_uri = utf8ToB64(_uri)
        dispatch(authGetGroupFirmsThunk(from_static_uri));

    }, [dispatch]);
    useEffect(() => {
        if(!firm_code) {
            window.location.href =IFURN_PRO_URL
            return
        }
        dispatch(onSetFirmDT(firm_code));
    }, [dispatch, firm_code])

    //** если пользователь найден по мылу или телефону*/
    useEffect(() => {
        if (isAuthFind) {
            formik.setFieldValue('isEditEmailOrPhone', false)
        }
    }, [isAuthFind, auth_lookup]);

    useEffect(() => {
        if (isAuthFind && !isSendShortPassword) {
            handlerStartSeconds(false);
        }
    }, [isSendShortPassword, isAuthFind]);

    const onHandlerSwitch = (isOpenFormFindUser, login) => {
        let is_email = isEmail(login)
        let email_field_data = is_email ? formik.values.email : ''
        let phone_field_data = !is_email ? formik.values.phone : ''
        if (!!email_field_data || !!phone_field_data) {
            let type_login_radio = !!email_field_data || !phone_field_data;
            formik.setFieldValue('isRadioLoginEmail', type_login_radio)
            formik.setFieldValue('email', email_field_data);
            formik.setFieldValue('phone', phone_field_data);

            formik.setFieldValue('isEditEmailOrPhone', true);
        }
        setIsFindUserForm(isOpenFormFindUser);
        handlerStartSeconds(false);
        formik.setErrors({})
    }

    return (
        <div className=" main-login-page-container">
            <Header project_rules={project_rules} firm_code={firm_code}>
                {!registration_state?.isSuccessful &&<div className="link register" style={{
                    marginLeft: !project_rules.infoText ? '0' : 'auto'
                }}
                                                          onClick={(e) => {
                                                              if (project_rules?.firm) {
                                                                  localStorage.setItem(LS_FIRM, project_rules?.firm);
                                                              }
                                                              dispatch(authLookupAC(null));
                                                              history.push({
                                                                  pathname: '/login/'+firm_code,
                                                                  state: {
                                                                      firm_code: firm_code
                                                                  }
                                                              })
                                                          }}
                > {getT('Вход')}</div>}
            </Header>
            {registration_state?.isSuccessful ?
                <div className="container justify-center items-center">
                    <h2>{getT('Вы успешно зарегистрировались!')}
                        <p>{getT('Для авторизации перейдите по ссылке')}

                            <span style={{textDecoration: "underline", color: "#4262FF", cursor: "pointer"}}
                                  onClick={(e) => {
                                      if (project_rules?.firm) {
                                          localStorage.setItem(LS_FIRM, project_rules?.firm);
                                      }
                                      dispatch(authLookupAC(null));
                                      history.push({
                                          pathname: '/login/'+firm_code,
                                          state: {
                                              firm_code: firm_code
                                          }
                                      })

                                  }}
                            >{window.location.host}</span>
                        </p></h2>
                </div>
                : <div className={"container w-100 flex-auto justify-center"}>
                    <FormikContext.Provider value={formik} autoComplete={"off"}>
                        {isFindUserForm ?
                            <LoginRegistration isButtonDisabled={isButtonDisabled} seconds={seconds}
                                               handlerStartSeconds={handlerStartSeconds}
                                               project_rules_name={project_rules?.name}
                                               onHandlerSwitch={onHandlerSwitch} setting_phone_code_country={project_rules?.phoneCode}/> :
                            <RegistrationUser onHandlerSwitch={onHandlerSwitch}
                                              project_rules_name={project_rules?.name} setting_phone_code_country={project_rules?.phoneCode}/>
                        }


                    </FormikContext.Provider>
                </div>
            }
        </div>
    );
};

export default SignUp;