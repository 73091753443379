import React, {useMemo} from 'react';
import InputForm from "../../../../../../../components/InputFields/InputForm/InputForm";
import {useLang} from "../../../../../../../context/langProvider";
import {useDispatch} from "react-redux";
import {useFormikContextProviderRegister} from "../../../../../SignUp";
import {changeAlertStatusAC} from "../../../../../../../store/reducers/CommonReducer";

const StepUserData = ({handleNext}) => {
    const {getT} = useLang();
    const dispatch = useDispatch();
    const {values, handleChange, setFieldValue, validateForm} = useFormikContextProviderRegister();
    const handleValidate = () => {
        let errors = {};
        // validateForm().then((errors) => {
        const regexPass = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()-_+=])[A-Za-z\d!@#$%^&*()-_+=]{8,30}$/
        if (values.phone && !values.email && !regexPass.test(values.password)) {
            errors['password'] = getT("Пароль должен состоять не менее, чем из 8 символов (чем длиннее пароль, тем лучше) Используйте сочетание по крайней мере 1 заглавной буквы, 1 цифры и 1 специального символа (#, @, &)");
        }
        if (values?.name?.length < 3) {
            errors['name'] = getT("Имя пользователя должно быть больше 3 символов");

        }
        // Handle the validation errors
        if (!Object.keys(errors).includes('name') && !Object.keys(errors).includes('password')) {
            handleNext()
        } else if (Object.keys(errors).includes('name') || Object.keys(errors).includes('password')) {
            let error_msg = `${errors['name'] || ''} ${errors['password'] || ''}`
            dispatch(changeAlertStatusAC(true, error_msg));
        }
        // });
    };


    return (
        <div>
            <InputForm label={<>{getT("Введите имя")} <sub style={{color: 'red', cursor: "pointer",  verticalAlign: "super"}}
                                                           title={getT("Имя пользователя должно быть больше 3 символов")}>*</sub></>}
                       id="name"

                       name="name"
                       cName={"mb-2"}
                       type="text" value={values.name} onChange={handleChange} required={true}/>

            {values.phone && !values.email &&
                <InputForm label={<>{getT('Введите пароль')} <sub style={{color: 'red', cursor: "pointer",  verticalAlign: "super"}}
                                                                  title={getT("Пароль должен состоять не менее, чем из 8 символов (чем длиннее пароль, тем лучше) Используйте сочетание по крайней мере 1 заглавной буквы, 1 цифры и 1 специального символа (#, @, &)")}>*</sub></>}
                           pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,12}$"
                           id="password"
                           cName={"mb-2"}
                           name="password"
                           required={true}
                           type="text" value={values.password} onChange={handleChange}/>
            }
            <button type={'button'} style={{margin: '0 auto'}} className={'btn'}
                    onClick={handleValidate}>{getT("Далее")}</button>

        </div>
    );
};

export default StepUserData;